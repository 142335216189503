/* TODO Bitte Datei aufräumen, wenn angeschaut @Tobias */
/*.PageFoot {*/
/*    width: 100%;*/
/*    position: relative;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    padding: 240px 0 0;*/
/*    overflow: hidden;*/
/*    background: linear-gradient(#fff 240px, #51595f 0);*/
/*    color: #fff;*/
/*    !* TODO Braucht man nicht, nur für macOSX - muss ein osX Designer gemacht haben =D, aber dafür gibts eine Tailwind Klasse*!*/
/*    -webkit-font-smoothing: antialiased;*/
/*}*/

/*.FootHead {*/
/*    @apply relative w-full m-auto;*/
/*}*/

/*.FootHead:before {*/
/*    @apply block absolute z-10;*/
/*    content: '';*/
/*    width: 2200px;*/
/*    height: 180px;*/
/*    top: -16px;*/
/*    left: -240px;*/
/*    border-radius: 50%;*/
/*    background: #51595f;*/
/*    transform: rotate(3deg);*/
/*}*/

/*.FootCity {*/
/*    @apply absolute;*/
/*    top: -196px;*/
/*    left: 32px;*/
/*    !*backface-visibility: hidden;*!*/
/*}*/


/*.FootBody {*/
/*    @apply m-auto min-h-[240px] relative z-20 p-[24px] pt-16;*/
/*    background: #51595f;*/
/*}*/

/*.Logo {*/
/*    z-index: 0;*/
/*    position: relative;*/
/*    display: flex;*/
/*    align-items: flex-end;*/
/*    color: #fff;*/
/*    letter-spacing: 2px;*/
/*    text-decoration: none;*/
/*    margin: 0 auto 62px;*/
/*}*/


/* TODO Kann man behalten. Die Animation muss halt custom definiert werden. */
@keyframes floating {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.Balloon {
    @apply absolute z-40;
    animation: floating 3s infinite;
    top: 50px;
}

